import { CaptureEvent } from "../captureEvent";
import { getGaScreenName } from "../screenNameMapper";

export class ForgotPasswordEvents extends CaptureEvent {
  screen = getGaScreenName("forgot-password");

  forgotPasswordEmailSendSuccessEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email"]);

    this.captureEvent({
      ...metadata,
      action: "success",
      id: "password_forget_success",
      label: "Forgot password email successfully sent"
    });
  }

  forgotPasswordEmailSendFailureEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email", "reason"]);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "password_forget_fail",
      label: "Failed to send forgot password email"
    });
  }
}

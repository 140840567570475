import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";
import { getShowScreenOptions } from "@fep/util";

export async function showRegistrationScreen(options: IShowScreenOptions) {
  const { containerID, redirect, __events, lang } = getShowScreenOptions(options);

  await showScreenSet({
    screenSet: "Registration",
    containerID,
    redirect,
    __events,
    lang
  });
}

// allow fep to initialize MarketDataService with lang from options, if exists
showRegistrationScreen.USE_LANG_PARAMETER = true;

import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showLoginScreenWithUrlContext } from "@fep/gigyaFunction/showLoginScreenWithUrlContext";
import { getShowScreenOptions } from "@fep/util";

export async function showLoginScreen(options: IShowScreenOptions): Promise<void> {
  const showLoginScreenOptions = getShowScreenOptions(options, {
    redirect: `${window.location.origin}${options.redirect.startsWith("/") ? "" : "/"}${options.redirect}`,
    registrationPath: `${window.location.origin}${options.registrationPath?.startsWith("/") ? "" : "/"}${options.registrationPath}`
  });

  showLoginScreenWithUrlContext(showLoginScreenOptions);
}

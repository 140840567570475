import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";
import { deleteSpecificCookie, getShowScreenOptions } from "@fep/util";

export function showLoginScreenWithUrlContext(options: IShowScreenOptions): void {
  if (window.fep.ssoEnabled) {
    const redirect = decodeURIComponent(options.redirect);
    const registrationPath = decodeURIComponent(options.registrationPath as string);
    deleteSpecificCookie({ name: `glt_${window.fep.apiKey}`, path: "/", domain: window.location.host, secure: true });
    window.gigya.sso.login({
      authFlow: "redirect",
      redirectURL: redirect,
      useChildContext: true,
      context: {
        registrationPath,
        redirect
      }
    });
  } else {
    const { containerID, __events } = getShowScreenOptions(options);
    void showScreenSet({
      screenSet: "Login",
      containerID,
      __events
    });
  }
}

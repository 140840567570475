import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";
import { getShowScreenOptions } from "@fep/util";

export async function showProvidePasswordScreen(options: IShowScreenOptions) {
  const { containerID, redirect, registrationPath, __events, lang } = getShowScreenOptions(options);

  await showScreenSet({
    screenSet: "ResetPassword",
    containerID,
    redirect,
    registrationPath,
    __events,
    lang
  });
}

// allow fep to initialize MarketDataService with lang from options, if exists
showProvidePasswordScreen.USE_LANG_PARAMETER = true;

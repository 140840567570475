import { IShowScreenSet } from "@fep/interfaces";
import { logger } from "@fep/services";
import { loadIntPhoneScript } from "@fep/gigyaFunction";

export async function showScreenSet({
  screenSet,
  containerID,
  screenSetKey,
  redirect,
  registrationPath,
  startScreen,
  __events = {},
  lang = window.fep.locale
}: IShowScreenSet) {
  try {
    await loadIntPhoneScript();
  } catch {
    logger.warn(`No phones provided for ${screenSet}`);
  }

  window.gigya.accounts.showScreenSet({
    screenSet,
    context: { market: window.fep.market, screenSetKey, redirect, registrationPath, startScreen },
    lang,
    containerID,
    startScreen,
    ...__events
  });
}

import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { logger, config } from "@fep/services";

export function getShowScreenOptions<T = IShowScreenOptions | string>(options?: T, showScreenOptions = {} as IShowScreenOptions) {
  if (typeof options === "string") {
    logger.warn(
      "Deprecated API usage, showScreen functions should be called with object instead of string"
    );
    showScreenOptions.containerID = options;
  } else {
    showScreenOptions = { ...options, ...showScreenOptions };
  }

  if (!config.data.__debugEvents) {
    delete showScreenOptions.__events;
  }

  return showScreenOptions;
}

import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { getAccountInfo, showLoginScreen } from "@fep/functions";
import { getImTokenData } from "@fep/gigyaFunction";

export class AuthService {
  private _imToken: string | null;
  private _isDeepLink: boolean;
  private _loginScreenOptions: IShowScreenOptions;

  constructor(loginScreenOptions: IShowScreenOptions) {
    this._imToken = this.getImToken();
    this._isDeepLink = this.getDeepLink();
    this._loginScreenOptions = loginScreenOptions;
  }

  async handleAuthentication(isProfilePage?: boolean, skipMarketInit?: boolean): Promise<void> {
    if ((this._imToken && this._isDeepLink) || !this._imToken || isProfilePage) {
      const isLoggedIn = await window.fep.functions.isLoggedIn({ skipMarketInit });
      if (isLoggedIn) {
        const { isEmailChecked } = window.gigya.thisScript.globalConf.customerData;
        if (this._imToken && !isEmailChecked) {
          await this.checkEmails(this._imToken);
        }
      } else {
        await showLoginScreen(this._loginScreenOptions);
      }
    }
  }

  private async checkEmails(imToken: string) {
    try {
      const accountInfo = await getAccountInfo();
      const imTokenData = await getImTokenData(imToken);
      if (imTokenData.profile.email !== accountInfo.profile.email) {
        await this.logoutWithRedirect();
      }
    } catch {
      await this.logoutWithRedirect();
    }
  }

  private async logoutWithRedirect() {
    await window.fep.functions.logout();
    await showLoginScreen(this._loginScreenOptions);
  }

  private getSearchParam(param: string) {
    return new URLSearchParams(window.location.search).get(param);
  }
  private getImToken() {
    return this.getSearchParam("imtoken");
  }
  private getDeepLink() {
    return this.getSearchParam("deeplink") !== null;
  }
}

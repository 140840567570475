import { CaptureEvent } from "../captureEvent";
import { getGaScreenName } from "../screenNameMapper";

export class LoginEvents extends CaptureEvent {
  screen = getGaScreenName("login");

  loginSuccessEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email", "type"]);

    this.captureEvent({
      ...metadata,
      action: "success",
      id: "login_success",
      label: "Login successful"
    });
  }

  loginFailureEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email", "reason", "type"]);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "login_fail",
      label: "Failed to login"
    });
  }
}

import { CaptureEvent } from "../captureEvent";
import { getGaScreenName } from "../screenNameMapper";

export class ResetPasswordEvents extends CaptureEvent {
  screen = getGaScreenName("reset-password__form");

  resetPasswordSuccessEvent() {
    this.captureEvent({
      action: "success",
      id: "password_reset_success",
      label: "Password reset success"
    });
  }

  resetPasswordFailEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["reason"]);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "password_reset_fail",
      label: "Password reset fail"
    });
  }
}

import { CaptureEvent } from "../captureEvent";
import { getGaScreenName } from "../screenNameMapper";

export class ChangePasswordEvents extends CaptureEvent {
  screen = getGaScreenName("change-password");

  passwordChangeSuccessEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email"]);

    this.captureEvent({
      action: "success",
      id: "password_change_success",
      label: "Password changed successfully"
    });
  }

  passwordChangeFailureEvent(metadata: Record<string, string>) {
    this.validateMetadata(metadata, ["email", "reason"]);

    this.captureEvent({
      ...metadata,
      action: "fail",
      id: "password_change_fail",
      label: "Password changing failed"
    });
  }
}

import { IScreenOptions } from "@fep/interfaces/iScreenOptions";
import { logger } from "@fep/services";

export const getScreenOptions = ({ lang }: { lang?: string }): IScreenOptions => {
  const locale = lang ?? window.fep.locale;

  if (locale && !locale.includes("-")) {
    logger.error("Incorrect locale is provided");
  }

  return {
    lang: locale?.split("-")[0],
    locale
  };
};

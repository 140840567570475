import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";
import { getShowScreenOptions } from "@fep/util";

export async function showRegistrationScreen(options: IShowScreenOptions) {
  const { containerID, redirect, __events } = getShowScreenOptions(options);

  await showScreenSet({
    screenSet: "Registration",
    containerID,
    redirect,
    phones: true,
    __events
  });
}

import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { AuthService } from "@fep/services";
import { showScreenSet } from "@fep/gigyaFunction";
import { getPageLocation } from "@fep/sharedFunctions";
import { getShowScreenOptions } from "@fep/util";

type IShowMyProfileScreenOptions = Omit<IShowScreenOptions, "redirect"> | string;

export async function showMyProfileScreen(options: IShowMyProfileScreenOptions) {
  const { pathname, search } = getPageLocation();
  const showScreenOptions = getShowScreenOptions<IShowMyProfileScreenOptions>(options, { redirect: pathname + search });
  const { containerID, __events, lang } = showScreenOptions;

  const authService = new AuthService(showScreenOptions);
  await authService.handleAuthentication(true);

  await showScreenSet({ screenSet: "MyProfile", containerID, screenSetKey: "myProfile", __events, lang });
}

// allow fep to initialize MarketDataService with lang from options, if exists
showMyProfileScreen.USE_LANG_PARAMETER = true;

export const isValidGln = (number: string): boolean => {
  const gln = number.toString();
  if (gln.length !== 13) {
    return false;
  }

  const sum = gln
    .slice(0, 12)
    .split("")
    .reduce((acc, digit, index) => {
      const num = parseInt(digit, 10);
      return acc + num * (index % 2 === 0 ? 1 : 3);
    }, 0);

  const checkDigit = (10 - (sum % 10)) % 10;
  return checkDigit === parseInt(gln[12], 10);
};

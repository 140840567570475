export const isValidBirthdate = (birthdate: string): boolean => {
  const birthDateObj = new Date(birthdate);
  if (isNaN(birthDateObj.getTime())) {
    return false;
  }

  const today = new Date();
  const age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  const dayDiff = today.getDate() - birthDateObj.getDate();

  if (age > 18) {
    return true;
  } else if (age < 18) {
    return false;
  } else {
    return monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0);
  }
};

import { IGigyaGlobalConf } from "@fep/interfaces";
import { IGigyaLoVsPerMarket } from "@fep/interfaces/gigya/thisScript/globalConf/iGigyaLoVsPerMarket";
import { IGigyaRegexPerMarket } from "@fep/interfaces/gigya/thisScript/globalConf/iGigyaRegexPerMarket";
import { config, FepConfig, FepError } from "@fep/services";
import { getScreenOptions } from "@fep/util/getScreenOptions";

export interface IMarketDataServiceMixin {
  config: FepConfig;
}

export interface IMarketData {
  conditionalFields: Record<string, Record<string, string[]>>;
  lovs: Record<string, IGigyaLoVsPerMarket>;
  regexes: Record<string, IGigyaRegexPerMarket>;
  translations: Record<string, string>;
}

export class MarketDataService {
  private static _instance: MarketDataService;

  data: IMarketData;

  // mixin
  config: FepConfig;

  constructor(mixin: IMarketDataServiceMixin) {
    this.config = mixin.config;

    this.data = {
      conditionalFields: {},
      lovs: {},
      regexes: {},
      translations: {}
    };
  }

  static getInstance(mixin: IMarketDataServiceMixin): MarketDataService {
    if (!this._instance) {
      this._instance = new MarketDataService(mixin);
    }

    return this._instance;
  }

  async init(options: { lang?: string }) {
    const { fepUrl, region } = this.config.data;
    const marketDataUrl = `${fepUrl}/external/screenSets/${region}/${window.fep.market.toLowerCase()}`;

    const { lang } = getScreenOptions(options);

    const [conditionalFields, lovs, regexes, translations] = await Promise.all([
      this._fetchJson(`${marketDataUrl}/conditionalFields.json`),
      this._fetchJson(`${marketDataUrl}/lovs.json`),
      this._fetchJson(`${marketDataUrl}/regexes.json`).then(this._transformRegexes),
      this._fetchJson(`${marketDataUrl}/translations-${lang}.json`)
    ]);

    this.data = {
      ...this.data,
      conditionalFields,
      lovs,
      regexes,
      translations
    };
  }

  private async _fetchJson(url: string) {
    const response = await fetch(url);
    if (response.status === 403) {
      throw new FepError(`Access forbidden to ${url}`);
    }
    if (!response.ok) {
      throw new FepError(`Failed to fetch ${url}: ${response.statusText}`);
    }
    return response.json();
  }

  private _transformRegexes(regexes: IGigyaGlobalConf["regexPerMarket"][0]) {
    return Object.keys(regexes).reduce((memo, key) => ({
      ...memo,
      [key]: {
        ...regexes[key],
        regex: new RegExp(regexes[key].regex)
      }
    }), {});
  }
}

export const marketDataService = MarketDataService.getInstance({ config });

import { parse } from "json5";
import { IConfig } from "@fep/interfaces";
import { FepError } from "@fep/services";
import { FepGigyaEvents, events } from "./events";
import { bindMethods } from "@fep/util";

export interface IFepConfigMixin {
  events: FepGigyaEvents;
}

export class FepConfig {
  private static _instance: FepConfig;
  private _data!: IConfig;

  // mixin
  events: FepGigyaEvents;

  private constructor(mixin: IFepConfigMixin) {
    this.events = mixin.events;
    bindMethods(this);
  }

  static getInstance(mixin: IFepConfigMixin): FepConfig {
    if (!this._instance) {
      this._instance = new FepConfig(mixin);
    }

    return this._instance;
  }

  get data() {
    if (!this._data) {
      return this._fetchAndParse();
    }

    return this._data;
  }

  set data(rawData: IConfig) {
    const configData = { ...this._fetchAndParse(), ...rawData };
    this._validate(configData);

    this._data = {
      ...configData,
      ssoEnabled: configData.ssoEnabled ?? true,
      gaEnabled: configData.gaEnabled ?? true
    };

    if (configData.events?.onLogin) {
      this.events.onLogin = configData.events.onLogin;
    }

    if (configData.events?.onLogout) {
      this.events.onLogout = configData.events.onLogout;
    }
  }

  private _validate(rawData: IConfig) {
    const requiredFields: Array<keyof IConfig> = ["apiKey", "lang", "market"];
    const validPortals: Array<typeof rawData.portal> = ["medicalPortal", "mConnect", "customerLink"];

    for (const field of requiredFields) {
      if (!rawData[field]) {
        throw new FepError(`Configuration validation error: '${field}' is required but was not provided.`);
      }
    }

    if (rawData.portal && !validPortals.includes(rawData.portal)) {
      throw new FepError(`Configuration validation error: 'portal' must be one of
        ${validPortals.join(", ")}, but was '${rawData.portal}'.`);
    }
  }

  private _fetchAndParse(): IConfig {
    let configData: Partial<IConfig> = {};

    const script: HTMLScriptElement = document.querySelector('script[src*="fep.js"]')!;

    // extract fepUrl from script src
    const fepUrlObject = new URL(script.src);
    const fepUrl = `${fepUrlObject.protocol}//${fepUrlObject.hostname}`;
    configData = { ...configData, fepUrl };

    // extract region from scriptUrl
    const match = /https:\/\/fep-([a-zA-Z]+)-/.exec(fepUrl);
    if (match) {
      const region = match[1];
      configData = { ...configData, region };
    } else {
      throw new FepError("Failed to parse the FEP script URL: Region not found.");
    }

    // get config object from script content
    const configTextObject = script?.textContent?.trim();
    if (configTextObject) {
      try {
        configData = { ...configData, ...parse(configTextObject) };
      } catch {
        throw new FepError("Failed to parse the FEP configuration script: Invalid JSON format. Please check the script content.");
      }
    }

    return configData as IConfig;
  }
}

export const config = FepConfig.getInstance({ events });

type ScreenNameMapping = {
  [key: string]: string;
};

const screenNameMapper: ScreenNameMapping = {
  "my-account": "My Profile",
  "register-step1": "Registration Step 1",
  "register-step2": "Registration Step 2",
  "register-step3": "Registration Step 3",
  "link-account": "Social Link Account",
  login: "Login",
  "missing-data": "Post Login",
  "social-post-login": "Social Post Login",
  "change-password": "Change Password",
  communication: "Communication Preferences",
  "create-password": "Create Password",
  "forgot-password": "Forgot Password",
  "provide-password": "Reset Password"
};

export function getGaScreenName(internalName: string): string {
  const screenName = screenNameMapper[internalName] || internalName;
  return screenName;
}

import { IGigyaIsAvailableLoginID } from "@fep/interfaces/gigya/accounts/iGigyaIsAvailableLoginID";
import { ga, logger } from "@fep/services";
import { isValidGln } from "@fep/services/validation/isValidGln";
import { isValidLuhn } from "@fep/services/validation/luhnControlSum";
import { isValidBirthdate } from "@fep/services/validation/isValidBirthdate";

const getErrors = (translations: Record<string, string>) => ({
  requestPassword: translations["registration-request-password"],
  efnNumber: translations["THIS_FIELD_IS_REQUIRED"],
  errorContent: translations["registration-login-error"],
  loginLink: translations["registration-login-link"],
  emailIsntEqual: translations["common__error__confirm-email-not-equal"],
  required: translations["THIS_FIELD_IS_REQUIRED"],
  consent: translations["common-consent__input-phone__required-error"],
  gln: translations["common__error__gln"],
  birthdate: translations["common__error__birthdate"]
});

const showEmailNotAvailable = (inputElement: HTMLInputElement, response: { isAvailable: boolean }) => {
  const { toggleErrorVisibility, setDeepValue } = window.gigya.thisScript.globalConf;
  const { validationFailed } = window.gigya.thisScript.globalConf.customerData;
  const { translations } = window.fep.marketDataService.data;

  const requestPassword = inputElement.parentElement?.parentElement?.querySelector(".fep__field-email-error-link");
  const errorContent = inputElement.parentElement?.parentElement?.querySelector(".fep__field-email-error-content");
  const loginLink = inputElement.parentElement?.parentElement?.querySelector(".fep__field-login-link");
  const isAvailable = requestPassword && errorContent && loginLink;

  if (isAvailable) {
    requestPassword.textContent = "";
    errorContent.textContent = "";
    loginLink.textContent = "";
    loginLink.setAttribute("style", "display: none");
  }

  if (!response.isAvailable) {
    toggleErrorVisibility(inputElement, translations["registration-input-email-is-registered"]);
    if (isAvailable) {
      loginLink.setAttribute("style", "display: inline-block");
      requestPassword.textContent = getErrors(translations).requestPassword;
      errorContent.textContent = getErrors(translations).errorContent;
      loginLink.textContent = getErrors(translations).loginLink;
    }

    setDeepValue(validationFailed, inputElement.name, true);

    inputElement?.classList.remove("fep__field-valid");
    inputElement?.classList.add("fep__field-error");
  }
};
let emailCheckCache: { [key: string]: { isAvailable: boolean } } = {};

const handleEmailUniqueValidation = (
  inputElement: HTMLInputElement,
  confirmInputElement?: HTMLInputElement | null
): Promise<{ isAvailable: boolean }> => {

  return new Promise((resolve, reject) => {
    const email = inputElement.value;

    // Use cached result if the email matches the confirmation email
    if (confirmInputElement && inputElement.value === confirmInputElement.value && emailCheckCache[email]) {
      showEmailNotAvailable(inputElement, emailCheckCache[email]);
      resolve(emailCheckCache[email]);
      return;
    }

    // Clear cache
    emailCheckCache = {};

    if (inputElement.value) {
      const idParams: IGigyaIsAvailableLoginID = {
        loginID: inputElement.value,
        callback: response => {
          if (response.errorCode) {
            return reject(new Error(response.errorMessage));
          }
          emailCheckCache[email] = response;
          showEmailNotAvailable(inputElement, response);
          resolve(response);
        }
      };
      window.gigya.accounts.isAvailableLoginID(idParams);
    }
  });
};

const handleSuccessValidation = (inputElement: HTMLInputElement, confirmInputElement: HTMLInputElement | null) => {
  const { toggleErrorVisibility, setDeepValue } = window.gigya.thisScript.globalConf;
  const { validationFailed } = window.gigya.thisScript.globalConf.customerData;

  inputElement?.parentElement?.classList.remove("fep__field-error");
  inputElement?.classList.add("fep__field-valid");

  if (confirmInputElement && confirmInputElement.value === inputElement.value) {
    confirmInputElement.classList.remove("fep__field-error");
    confirmInputElement.classList.add("fep__field-valid");
    toggleErrorVisibility(confirmInputElement, "");
    setDeepValue(validationFailed, confirmInputElement.name, false);
  }

  if (inputElement.type !== "checkbox") {
    toggleErrorVisibility(inputElement, "");
  }

  setDeepValue(validationFailed, inputElement.name, false);
};

const getErrorMessage = (validationType: string, regExpMessage: string) => {
  const { translations } = window.fep.marketDataService.data;

  if (!translations) {
    return "";
  }

  let message = getErrors(translations).required;

  switch (validationType) {
    case "confirm":
      message = getErrors(translations).emailIsntEqual;
      break;
    case "regexp":
      message = translations[regExpMessage] ?? regExpMessage;
      break;
    case "efn-number":
      message = getErrors(translations).efnNumber;
      break;
    case "consent":
      message = getErrors(translations).consent;
      break;
    case "gln":
      message = getErrors(translations).gln;
      break;
    case "birthdate":
      message = getErrors(translations).birthdate;
      break;
  }

  return message;
};

const handleInvalidValidation = (inputElement: HTMLInputElement, validationType: string, regExpMessage: string) => {
  const { toggleErrorVisibility, setDeepValue } = window.gigya.thisScript.globalConf;
  const { validationFailed } = window.gigya.thisScript.globalConf.customerData;

  if (inputElement.type !== "checkbox") {
    toggleErrorVisibility(inputElement, getErrorMessage(validationType, regExpMessage));
  }
  inputElement?.classList.add("fep__field-error");
  inputElement?.parentElement?.classList.add("fep__field-error");
  setDeepValue(validationFailed, inputElement.name, true);
};

const handleRequiredValidation = (value: string) => {
  return !!value;
};

const handleRegexValidation = (value: string, regExpString: string | RegExp): boolean => {
  const regExp = new RegExp(regExpString, "u");

  return !!value && regExp.test(value);
};

const handleConfirmValidation = (value: string, confirmValue?: string): boolean => {
  return confirmValue !== "" ? value === confirmValue : true;
};

const handleConsentValidation = (value: string): boolean => {
  return !!value;
};

const handleEfnNumberValidation = (value: string): boolean => {
  return isValidLuhn(value);
};

const handleGlnValidation = (value: string): boolean => {
  return isValidGln(value);
};

interface IValidateFieldValue {
  value: string;
  confirmValue?: string;
  validationType: string;
  regExpString?: string | RegExp;
}

export function validateFieldValue({ value, confirmValue, validationType, regExpString }: IValidateFieldValue): boolean {
  let isValid = true;

  switch (validationType) {
    case "required":
      isValid = handleRequiredValidation(value);
      break;
    case "confirm":
      isValid = value && confirmValue ? handleConfirmValidation(value, confirmValue) : true;
      break;
    case "regexp":
      isValid = value.length > 0 && !!regExpString ? handleRegexValidation(value, regExpString) : true;
      break;
    case "consent":
      isValid = handleConsentValidation(value);
      break;
    case "efn-number":
      isValid = value?.length > 0 ? handleEfnNumberValidation(value) : true;
      break;
    case "gln":
      isValid = value?.length > 0 ? handleGlnValidation(value) : true;
      break;
    case "birthdate":
      isValid = value ? isValidBirthdate(value) : true;
      break;
  }

  return isValid;
}

function trackFieldValidationFailure(fieldName: string, screenName: string) {
  ga.validation?.fieldValidationFailedEvent({
    field: fieldName,
    screen: screenName
  });
}

export function validateField(inputElement: HTMLInputElement, fieldValidationTypes: string[], screenName?: string) {
  const { marketDataService } = window.fep;
  const { regexes } = marketDataService.data;

  const fieldName = inputElement.name;
  const currentRegexFieldName = fieldName.replace("local.", "");
  const confirmInputElement = document.querySelector<HTMLInputElement>(`#${inputElement?.dataset.confirm}`);
  const { regex: regExpString, message: regExpMessage } = (regexes[currentRegexFieldName] || {});
  // Clear validation before new run
  inputElement?.classList.remove("fep__field-valid", "fep__field-error");

  let isValid = true;
  const validationPromises: Array<Promise<void>> = [];

  for (const validationType of fieldValidationTypes) {
    if (validationType === "check-email") {
      // Handle asynchronous validations
      const emailCheckPromise = handleEmailUniqueValidation(inputElement, confirmInputElement).then(response => {
        if (!response.isAvailable) {
          isValid = false;
          screenName && trackFieldValidationFailure(fieldName, screenName);
        }
      }).catch(error => {
        logger.error(error);
      });
      validationPromises.push(emailCheckPromise);
    } else {
      // Handle synchronous validations
      isValid = validateFieldValue({
        value: inputElement.value,
        confirmValue: confirmInputElement?.value,
        validationType,
        regExpString
      });

      if (!isValid) {
        handleInvalidValidation(inputElement, validationType, regExpMessage);
        screenName && trackFieldValidationFailure(fieldName, screenName);
        break;
      }
    }
  }

  Promise.all(validationPromises).then(() => {
    if (isValid) {
      handleSuccessValidation(inputElement, confirmInputElement);
    }
  }).catch(error => {
    logger.error(error);
  });

  if (validationPromises.length === 0 && isValid) {
    handleSuccessValidation(inputElement, confirmInputElement);
  }
}

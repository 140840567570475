import { showScreenSet } from "@fep/gigyaFunction";
import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { getShowScreenOptions } from "@fep/util";

export async function showEmailVerificationScreen(options: IShowScreenOptions | string) {
  const { containerID, redirect, registrationPath, __events } = getShowScreenOptions(options);

  const emailVerified = new URLSearchParams(window.location.search).get("verified");
  const startScreen = emailVerified === "true" ? "confirm-email__succeeded" : "confirm-email__failed";

  await showScreenSet({
    screenSet: "ConfirmEmail",
    redirect,
    registrationPath,
    containerID,
    startScreen,
    __events
  });
}

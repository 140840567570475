import { showScreenSet } from "@fep/gigyaFunction";
import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { getShowScreenOptions } from "@fep/util";

export async function showCreatePasswordScreen(options: IShowScreenOptions | string) {
  const { containerID, __events } = getShowScreenOptions(options);

  const urlParams = new URLSearchParams(window.location.search);
  const prtoken = urlParams.get("prtoken");
  const imtoken = urlParams.get("imtoken");
  let token = prtoken;
  if (imtoken && !prtoken) {
    token = imtoken;
  }
  const startScreen = token ? "create-password__form" : "create-password__failed";

  await showScreenSet({
    screenSet: "CreatePassword",
    containerID,
    startScreen,
    __events
  });
}

import {
  AuthService,
  CommunicationConsentService,
  loadScript,
  logger
} from "@fep/services";
import { getEnv } from "@fep/env";
import type { Stage } from "@fep/env/types";
import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { getShowScreenOptions } from "@fep/util";
import { getPageLocation } from "@fep/sharedFunctions";

type IShowCommunicationPreferencesScreenOptions = Omit<IShowScreenOptions, "redirect"> | string;

const env = getEnv(process.env.TARGET_ENV as Stage);

export async function showCommunicationPreferencesScreen(options: IShowCommunicationPreferencesScreenOptions) {
  try {
    const { pathname, search } = getPageLocation();
    const showScreenOptions = getShowScreenOptions<IShowCommunicationPreferencesScreenOptions>(options, { redirect: pathname + search });
    const { containerID: containerId = "fep-main", lang } = showScreenOptions;

    const authService = new AuthService(showScreenOptions);
    await authService.handleAuthentication(false, true);

    const externalBundleUrl = new URL(`${env.FEP_URL}/external/communication-preferences.js`);

    await loadScript(externalBundleUrl);

    const { market } = window.fep;

    const consentService = new CommunicationConsentService();

    window.communicationPreferences.render({
      market,
      lang: lang as string,
      consentService,
      containerId
    });
  } catch (error) {
    logger.error(error);
  }
}

// skip loadGigya proxied execution
showCommunicationPreferencesScreen.SKIP_LOAD_GIGYA = true;

// allow fep to initialize MarketDataService with lang from options, if exists
showCommunicationPreferencesScreen.USE_LANG_PARAMETER = true;

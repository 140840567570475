import type {
  ISetAccountInfoOptions
} from "@fep/interfaces";
import type {
  ChannelTypes,
  IAccountConsent,
  IPreferences,
  IPhone,
  IAccountProfile,
  IFepConsents,
  IFepConsentService,
  IFepConsentDetails,
  IConsentsAccount,
  IFepConsent
} from "@msd-cex/sap-cdc-shared";
import { getAccountInfo, setAccountInfo } from "@fep/functions";
import { getDeepValue, setDeepValue, getSsoGigyaBaseUrl } from "@fep/gigyaFunction";
import { ConsentStateManager } from ".";
import { getEnv } from "@fep/env";
import type { Stage } from "@fep/env/types";
import { FepError } from "../error";
import { ga } from "@fep/services";

type ExtractedAccountData = {
  consents: IConsentsAccount | undefined;
  phones: IPhone[] | undefined;
  email: string;
};

interface IConsentCapturedCDC {
  channelType: ChannelTypes;
  channelValue: string;
  optIn: boolean;
  updatedBy: string;
  disclaimer?: string;
  referral?: string;
  updateChannelValueInProfile?: boolean;
  deleteChannelValueInProfile?: boolean;
}

const env = getEnv(process.env.TARGET_ENV as Stage);

interface ICommunicationConsentMap {
  phone: { [key: string]: string };
  email: { [key: string]: string };
}

export class CommunicationConsentService implements IFepConsentService {
  private get apiUrl() {
    return `${env.IMTOKEN_PROFILE_URL}/profile`;
  }
  private get unsubscribeApiUrl() {
    return `${env.IMTOKEN_UNSUBSCRIBE_URL}/unsubscribe`;
  }

  private isSessionMode: boolean = true;
  private initialConsentData: IFepConsents = undefined;

  private communicationConsentMap: ICommunicationConsentMap = {
    phone: {
      "communication.phone": "phone",
      "communication.sms": "sms",
      "communication.fax": "fax"
    },
    email: {
      "communication.emailCommunication": "emailCommunication"
    }
  };

  private data: ExtractedAccountData | undefined;

  private consentStateManager!: ConsentStateManager;

  constructor() {
    const imToken = this.getImToken();
    if (imToken) {
      const deepLink = this.getDeepLink();
      if (!deepLink) {
        this.isSessionMode = false;
      }
    }
  }

  private initializeConsentStateManager() {
    if (this.initialConsentData) {
      this.consentStateManager = new ConsentStateManager(this.initialConsentData);
    }
  }

  async get(): Promise<IFepConsent[]> {
    this.data = await this.extractAccountData();
    const details = await this.getSiteConsentDetails();
    this.initialConsentData = this.mapConsentData(details, this.data);
    this.initializeConsentStateManager();
    return this.initialConsentData as IFepConsent[];
  }

  async update(changes: IFepConsents): Promise<{ success: boolean; errorMessage?: string }> {
    try {
      await this.updateConsentData(changes);

      if (this.data && changes) {
        const { email } = this.data;
        this.sendCommunicationUpdateEvent(changes, email);
      }

      return { success: true };
    } catch (e: unknown) {
      return { success: false, errorMessage: e as string };
    }
  }

  private sendCommunicationUpdateEvent(changes: IFepConsents, email: string): void {
    const consents = this.prepareConsentData(changes);

    ga.editProfile?.communicationUpdateSuccessEvent({
      email,
      consents
    });
  }

  private prepareConsentData(changes: IFepConsents): { [key: string]: boolean } {
    const consents: { [key: string]: boolean } = {};

    changes?.forEach(consent => {
      const { consentId, isConsentGranted } = consent;
      const consentName = consentId.replace("communication.", "");
      if (consentName) {
        consents[consentName] = isConsentGranted ?? false;
      }
    });

    return consents;
  }

  private getSapConsentDetailsApiUrl() {
    const gigyaBaseApiUrl = getSsoGigyaBaseUrl();
    return `${gigyaBaseApiUrl}/accounts.getSiteConsentDetails`;
  }

  private async extractAccountData(): Promise<ExtractedAccountData> {
    return this.isSessionMode ? this.extractAccountDataFromSession() : this.extractAccountDataFromImToken();
  }

  private async updateConsentData(changes: IFepConsents) {
    return this.isSessionMode
      ? this.updateConsentDataInUserAccount(changes)
      : this.updateConsentDataUnsubscribe(changes);
  }

  private async updateConsentDataInUserAccount(changes: IFepConsents) {
    const accountInfo = this.fillConsentsMetaInfo(changes);
    await setAccountInfo(accountInfo);
  }

  private fillConsentsMetaInfo(changes: IFepConsents): ISetAccountInfoOptions {
    const preferences = {} as IPreferences;
    const profile = {} as IAccountProfile;

    const updatedBy = window.fep.portal;
    const referrer = window.location.href;

    changes?.forEach(consent => {
      const { consentValue, consentId, consentText, isConsentGranted } = consent;
      const previousConsent = this.consentStateManager.getPreviousConsent(consentId);
      const { latestConsentValue: prevConsentValue, latestConsentStatus: wasConsentGranted } = previousConsent;

      if (prevConsentValue !== consentValue || wasConsentGranted !== isConsentGranted) {
        const optIn = isConsentGranted ?? false;
        const channelValue = consentValue;
        const disclaimer = consentText;
        const entitlements = (optIn && [channelValue]) || [];
        const tags = [updatedBy, referrer, disclaimer];
        const phoneConsentId = this.communicationConsentMap.phone[consentId];

        if (isConsentGranted || wasConsentGranted) {
          setDeepValue(preferences, consentId, {
            entitlements,
            isConsentGranted: optIn,
            tags
          });
        }

        this.consentStateManager.updateConsentState(consentId, consentValue, optIn);

        if (phoneConsentId) {
          profile.phones = this.getProfilePhones(consent);
        }
      }
    });

    return {
      preferences,
      ...(profile.phones ? { profile } : {})
    };
  }

  private getProfilePhones(consent: IFepConsent) {
    const { consentValue: channelValue, consentId } = consent;
    const phoneConsentId = this.communicationConsentMap.phone[consentId];
    let phones = this.data?.phones;

    if (!phones) {
      phones = [];
    }

    const phoneIndex = phones?.findIndex(phone => phone.type === phoneConsentId);

    if (channelValue) {
      if (phoneIndex > -1) {
        phones[phoneIndex].number = channelValue;
      } else {
        phones.push({
          type: phoneConsentId,
          number: channelValue
        });
      }
    } else if (phoneIndex > -1) {
      phones.splice(phoneIndex, 1);
    }

    return phones;
  }

  private getInitialConsentById(consentId: string) {
    return this.initialConsentData?.find(consent => consentId === consent.consentId);
  }

  private async updateConsentDataUnsubscribe(changes: IFepConsents) {
    // error handling needed
    const imToken = this.getImToken() ?? "";
    const response = await fetch(this.unsubscribeApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        imToken,
        consents: this.createConsentsMetaInfo(changes)
      })
    });

    return response.ok;
  }

  private createConsentsMetaInfo(changes: IFepConsents): IConsentCapturedCDC[] | undefined {
    const updatedBy = window.fep.portal;
    const referral = window.location.href;

    return changes?.filter(consent => {
      const { consentId, consentValue, isConsentGranted } = consent;
      const previousConsent = this.consentStateManager.getPreviousConsent(consentId);

      const hasValueChanged = previousConsent.latestConsentValue !== consentValue;
      const hasStatusChanged = previousConsent.latestConsentStatus !== isConsentGranted;

      return hasValueChanged || hasStatusChanged;
    }).map(consent => {
      const { consentValue, consentId, consentText, isConsentGranted } = consent;

      this.consentStateManager.updateConsentState(consentId, consentValue, isConsentGranted ?? false);

      const updatePhoneInProfile = !!this.communicationConsentMap.phone[consentId];
      const updateChannelValueInProfile = updatePhoneInProfile && !!consentValue;
      const deleteChannelValueInProfile = updatePhoneInProfile && !consentValue;

      return {
        channelType: consentId?.replace("communication.", "") as ChannelTypes,
        channelValue: consentValue ?? "",
        optIn: isConsentGranted || false,
        updatedBy,
        referral,
        disclaimer: consentText,
        updateChannelValueInProfile,
        deleteChannelValueInProfile
      };
    });
  }

  private async getSiteConsentDetails(): Promise<IFepConsentDetails> {
    // error handling needed
    const response = await fetch(`${this.getSapConsentDetailsApiUrl()}?APIKey=${window.fep.apiKey}`);

    const json = await response.json();
    return json?.siteConsentDetails as IFepConsentDetails;
  }

  private mapConsentData(details: IFepConsentDetails, data: ExtractedAccountData): IFepConsents {
    const { market, lang } = window.fep;
    const { consents, phones, email } = data || {};

    return Object.entries(details)
      .map(([k, v]) => {
        const consent = getDeepValue(consents, k) as IAccountConsent;
        const { customData, legalStatements } = v;
        const consentId = k;
        const shouldDisplayConsent = customData?.find(a => a.key === "onComPrefScreen")?.value?.includes(market);
        const mapped = {
          consentId,
          shouldDisplayConsent,
          consentText: legalStatements?.[lang]?.purpose,
          isReadOnly: false
        };

        const phoneConsentType = this.communicationConsentMap.phone[consentId];
        const emailConsentType = this.communicationConsentMap.email[consentId];

        if (shouldDisplayConsent) {
          const { optIn: isConsentGranted, channelValue: consentValue } = consent || {};

          let defaultConsentValue: string | undefined = consentValue;

          if (!defaultConsentValue) {
            if (emailConsentType) {
              defaultConsentValue = email;
            } else if (phoneConsentType && phones?.length) {
              const number = phones.find(phone => phone.type === phoneConsentType)?.number;
              if (number) {
                defaultConsentValue = number;
              }
            }
          }

          if (emailConsentType) {
            mapped.isReadOnly = true;
          }

          return { ...mapped, isConsentGranted, consentValue: defaultConsentValue };
        }

        return null;
      })
      .filter(a => a !== null) as IFepConsents;
  }

  private async extractAccountDataFromSession(): Promise<ExtractedAccountData> {
    const data = await getAccountInfo();
    return {
      consents: data?.profile?.consents,
      phones: data?.profile?.phones,
      email: data?.profile?.email
    };
  }

  private async extractAccountDataFromImToken(): Promise<ExtractedAccountData> {
    const profile = await this.getImTokenProfile();
    const emptyObject = new Object();
    return {
      consents: profile?.consents ?? emptyObject,
      phones: profile?.phones,
      email: profile?.email
    };
  }

  private async getImTokenProfile(): Promise<IAccountProfile> {
    const imToken = this.getImToken();
    const response = await fetch(`${this.apiUrl}?imtoken=${imToken}`);

    if (response.ok) {
      const { profile } = await response.json();
      return profile;
    } else {
      const errorMessage = await response.text();
      throw new FepError(errorMessage, response);
    }
  }

  private getSearchParam(param: string) {
    return new URLSearchParams(window.location.search).get(param);
  }
  private getImToken() {
    return this.getSearchParam("imtoken");
  }
  private getDeepLink() {
    return this.getSearchParam("deeplink") !== null;
  }
}

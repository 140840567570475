import { IShowScreenSet } from "@fep/interfaces";
import { logger } from "@fep/services";
import { getTranslations, loadIntPhoneScript } from "@fep/gigyaFunction";

export async function showScreenSet({
  screenSet,
  containerID,
  screenSetKey,
  redirect,
  registrationPath,
  startScreen,
  phones,
  __events = {}
}: IShowScreenSet) {
  if (!window.gigya.thisScript.globalConf.translations) {
    try {
      const translations = await getTranslations(screenSet);
      window.gigya.thisScript.globalConf.translations = translations;
    } catch (err) {
      logger.warn(`No translations provided for ${screenSet}`);
    }
  }

  if (phones) {
    try {
      await loadIntPhoneScript();
    } catch (err) {
      logger.warn(`No phones provided for ${screenSet}`);
    }
  }

  window.gigya.accounts.showScreenSet({
    screenSet,
    context: { market: window.fep.market, screenSetKey, redirect, registrationPath, startScreen },
    lang: window.fep.lang,
    containerID,
    startScreen,
    ...__events
  });
}

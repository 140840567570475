import type { FepFunctions, IConfig } from "@fep/interfaces";
import * as functions from "@fep/functions";
import * as sharedFunctions from "@fep/sharedFunctions";
import { injectFonts, FepError, GoogleAnalytics, ga } from "@fep/services";
import { FepLogger, logger } from "@fep/services/logger";
import { FepConfig, config } from "@fep/services/config";
import { FepGigyaEvents, events } from "@fep/services/config/events";
import { GigyaLibManager, gigyaLibManager } from "@fep/services/gigya";
import { MarketDataService, marketDataService } from "@fep/services/marketData";
import { version } from "../package.json";
import { ISapAccount } from "@msd-cex/sap-cdc-shared";
import { handleBFCacheRestore } from "@fep/util";
import { IShowScreenOptions } from "./interfaces/iShowScreenOptions";

export interface IFepMixin {
  logger: FepLogger;
  config: FepConfig;
  events: FepGigyaEvents;
  gigyaLibManager: GigyaLibManager;
  ga: GoogleAnalytics;
  marketDataService: MarketDataService;
}

export class Fep {
  private _sharedFunctions?: FepFunctions;
  private _functions?: FepFunctions;

  // mixin
  logger: FepLogger;
  config: FepConfig;
  events: FepGigyaEvents;
  gigyaLibManager: GigyaLibManager;
  ga: GoogleAnalytics;
  marketDataService: MarketDataService;

  constructor(mixin: IFepMixin) {
    this.logger = mixin.logger;
    this.config = mixin.config;
    this.events = mixin.events;
    this.gigyaLibManager = mixin.gigyaLibManager;
    this.ga = mixin.ga;
    this.marketDataService = mixin.marketDataService;

    handleBFCacheRestore();
    injectFonts();
  }

  get version(): string {
    return version;
  }

  get lang(): string {
    return this.config.data.lang ?? window.gigya?.thisScript?.globalConf?.lang ?? "en";
  }

  get market(): string {
    return window.gigya?.thisScript?.globalConf?.market ?? this.config.data.market ?? "";
  }

  get portal(): string {
    return window.gigya?.thisScript?.globalConf?.portal ?? this.config.data.portal ?? "cdc;";
  }

  get apiKey(): string {
    return this.config.data.apiKey ?? "";
  }

  get locale(): string {
    return `${this.config.data.lang}-${window.gigya?.thisScript?.globalConf?.market ?? this.config.data.market ?? ""}`;
  }

  get ssoEnabled(): boolean {
    return !!this.config.data.ssoEnabled;
  }

  get sharedFunctions(): FepFunctions {
    if (!this._sharedFunctions) {
      this._sharedFunctions = sharedFunctions as unknown as FepFunctions;
    }

    return this._sharedFunctions;
  }

  // alias to expose fep.functions on window.fep.fn
  get fn(): FepFunctions {
    return this.functions;
  }

  get functions(): FepFunctions {
    if (!this._functions) {
      const onBefore = async (
        {
          skipLoadGigya = false,
          lang = undefined,
          skipMarketInit = false
        }: { skipLoadGigya: boolean; lang: string | undefined; skipMarketInit?: boolean }
      ) => {
        const promises = [];

        if (!skipMarketInit) {
          // load market data
          promises.push(this.marketDataService.init({ lang }));
        }

        if (!skipLoadGigya) {
          // load gigya lib
          promises.push(this.gigyaLibManager.init());
        }

        await Promise.all(promises);
      };

      // decorate every fep.functions.methodName
      this._functions = new Proxy(functions, {
        get(target, prop, receiver) {
          const original = Reflect.get(target, prop, receiver);
          if (typeof original === "function") {
            return async (...args: Parameters<typeof original>) => {
              await onBefore(
                {
                  skipLoadGigya: original.SKIP_LOAD_GIGYA,
                  lang: original.USE_LANG_PARAMETER ? (args[0] as IShowScreenOptions)?.lang : undefined,
                  skipMarketInit: (args[0] as Record<string, boolean | undefined>)?.skipMarketInit
                }
              );
              return original.apply(this, args);
            };
          }
          return original;
        }
      }) as unknown as FepFunctions;
    }

    return this._functions;
  }

  /**
   * @deprecated keeping for backward compatibility.
   */
  set onLogin(onLoginCallback: (account: ISapAccount) => void | Promise<void>) {
    this.logger.warn("Please do not set onLogin directly. Events should be set via fep.addEventListener.");

    this.events.onLogin = onLoginCallback;
  }

  /**
   * @deprecated keeping for backward compatibility.
   */
  set onLogout(onLogoutCallback: () => void | Promise<void>) {
    this.logger.warn("Please do not set onLogout directly. Events should be set via fep.addEventListener.");

    this.events.onLogout = onLogoutCallback;
  }

  addEventListener(...args: Parameters<typeof this.events.addEventListener>): void {
    return this.events.addEventListener(...args);
  }

  /**
   * @deprecated keeping for backward compatibility
   *
   * Just in case this public method was used anywhere.
   */
  setConfig(config: IConfig) {
    this.logger.warn(`Please do not call setConfig directly. This method is redundant and will be decomissioned soon.
      The configuration object should be passed within the FEP Script. Refer to the FEP Integration Guide for details.`);

    this.config.data = config;
  }

  /**
   * @deprecated keeping for backward compatibility
   */
  async loadGigya(configData?: IConfig) {
    this.logger.warn(`Please do not call loadGigya directly. This method is redundant and will be decomissioned soon.
      The loading is checked and executed automatically after calling any first method from fep.functions.methodName.`);

    await this.gigyaLibManager.init(configData);
  }

  isFepError(error: Error): boolean {
    return error instanceof FepError;
  }
}

Object.defineProperty(window, "fep", {
  value: new Fep({ logger, config, events, gigyaLibManager, ga, marketDataService }),
  writable: false,
  enumerable: true,
  configurable: true
});

import { getEnv } from "@fep/env";
import type { Stage } from "@fep/env/types";

export function setupAssets(containerId?: string): void {
  const env = getEnv(process.env.TARGET_ENV as Stage);
  const assetStubPrefix = "FEP_ASSET_STUB";
  const querySelector = containerId ? `#${containerId} [data-src]` : "[data-src]";
  const elements = document.querySelectorAll<HTMLElement>(querySelector);
  const assetUrl = `${env.FEP_URL}`;

  elements.forEach((element: HTMLElement) => {
    const src = element.getAttribute("data-src");
    if (src?.includes(assetStubPrefix)) {
      const updatedSrc = src.replace(assetStubPrefix, assetUrl);
      if (element instanceof HTMLImageElement) {
        element.setAttribute("src", updatedSrc);
      } else {
        element.style.backgroundImage = `url('${updatedSrc}')`;
      }
    }
  });
}

import { IPageLocation } from "@fep/interfaces";
import { logger } from "@fep/services";

export function getPageLocation(): IPageLocation {
  const parse = (pageLocation: IPageLocation) => ({
    href: pageLocation.href,
    pathname: pageLocation.pathname,
    search: pageLocation.search,
    origin: pageLocation.origin
  });

  try {
    if (window.top) {
      return parse(window.top.location);
    }
  } catch (error) {
    logger.warn("Could not get location from window.top object", error);
  }

  // Fallback to document.referrer in case of cross-origin restrictions only
  return document.referrer
    ? parse(new URL(document.referrer))
    : parse(window.location);
}

import { AuthService, CommunicationConsentService, getPageLocation, loadScript, logger } from "@fep/services";
import { getEnv } from "@fep/env";
import type { Stage } from "@fep/env/types";
import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { getShowScreenOptions } from "@fep/util";

type IShowCommunicationPreferencesScreenOptions = Omit<IShowScreenOptions, "redirect"> | string;

const env = getEnv(process.env.TARGET_ENV as Stage);

export async function showCommunicationPreferencesScreen(options: IShowCommunicationPreferencesScreenOptions) {
  try {
    const { pathname, search } = getPageLocation();
    const showScreenOptions = getShowScreenOptions<IShowCommunicationPreferencesScreenOptions>(options, { redirect: pathname + search });
    const { containerID: containerId = "fep-main" } = showScreenOptions;

    const authService = new AuthService(showScreenOptions);
    await authService.handleAuthentication();

    const externalBundleUrl = new URL(`${env.FEP_URL}/external/communication-preferences.js`);

    await loadScript(externalBundleUrl);

    const { market, lang } = window.fep;

    const consentService = new CommunicationConsentService();

    window.communicationPreferences.render({
      market,
      lang,
      consentService,
      containerId
    });
  } catch (error) {
    logger.error(error);
  }
}

import { ILoVOption } from "@fep/interfaces/gigya/thisScript/globalConf/iLoVOption";

export function clearSelectOptions(selectElement: HTMLSelectElement): void {
  while (selectElement.options.length > 0) {
    selectElement.options.remove(0);
  }
}

export function clearRadioElements(selectElement: HTMLElement): void {
  selectElement.querySelectorAll(".gigya-multi-choice-item").forEach(item => item.remove());
}

export function populateSelectWithOptions(selectElement: HTMLSelectElement, lov: ILoVOption[]): void {
  for (const { value, text, disabled } of lov) {
    const option = document.createElement("option");

    option.value = value;
    option.textContent = text;
    if (disabled) {
      option.dataset.disabled = disabled.toString();
    }

    selectElement.add(option);
  }
}

export function populateRadioWithOptions(selectElement: HTMLElement, lov: ILoVOption[]): void {
  for (const { value, text, selected } of lov) {
    const wrapper = document.createElement("div");
    const input = document.createElement("input");
    const label = document.createElement("label");

    wrapper.className = "gigya-multi-choice-item";

    input.className = "gigya-input-radio fep__radio-item-input";
    input.type = "radio";
    input.name = selectElement.getAttribute("data-inputName") ?? "";
    input.value = value;
    if (selected) input.setAttribute("checked", "checked");

    label.className = "fep__radio-item-label";
    label.textContent = text;
    label.setAttribute("data-binding", "true");

    label.appendChild(input);
    wrapper.appendChild(label);

    selectElement.appendChild(wrapper);
  }
}

export function translate(lov: ILoVOption) {
  const { marketDataService } = window.fep;
  const { translations } = marketDataService.data;

  return {
    ...lov,
    text: translations[lov.text]
  };
}

export function fillLoVs() {
  const { marketDataService } = window.fep;
  const { lovs } = marketDataService.data;
  for (const { id, lov } of Object.values(lovs)) {
    document.querySelectorAll<HTMLSelectElement>(`select[data-screenset-element-id=${id}]`).forEach(field => {
      const translatedLoV = lov.map(translate);
      clearSelectOptions(field);
      populateSelectWithOptions(field, translatedLoV);
    });
    if (document.querySelectorAll(`div[data-screenset-element-id=${id}] input[type="radio"]`).length <= 0) {
      document.querySelectorAll<HTMLElement>(`div[data-screenset-element-id=${id}]`).forEach(field => {
        const translatedLoV = lov.map(translate);
        clearRadioElements(field);
        populateRadioWithOptions(field, translatedLoV);
      });
    }
  }
}

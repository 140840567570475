import { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { showScreenSet } from "@fep/gigyaFunction";
import { getShowScreenOptions } from "@fep/util";

export async function showProvidePasswordScreen(options: IShowScreenOptions) {
  const { containerID, redirect, registrationPath, __events } = getShowScreenOptions(options);

  await showScreenSet({
    screenSet: "ResetPassword",
    containerID,
    redirect,
    registrationPath,
    __events
  });
}

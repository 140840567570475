import * as gigyaFunctions from "@fep/gigyaFunction";
import { loadScript } from "@fep/services";
import { FepConfig, config } from "@fep/services/config";
import { FepGigyaEvents, events } from "@fep/services/config/events";
import { getSsoGigyaBaseUrl } from "@fep/gigyaFunction/getSsoGigyaBaseUrl";
import { IConfig } from "@fep/interfaces";
import { bindMethods } from "@fep/util";

export interface IGigyaLibManagerMixin {
  config: FepConfig;
  events: FepGigyaEvents;
}

export class GigyaLibManager {
  private static _instance: GigyaLibManager;
  private _initialized = false;

  // mixin
  config: FepConfig;
  events: FepGigyaEvents;

  private constructor(mixin: IGigyaLibManagerMixin) {
    this.config = mixin.config;
    this.events = mixin.events;
    bindMethods(this);
  }

  static getInstance(mixin: IGigyaLibManagerMixin): GigyaLibManager {
    if (!this._instance) {
      this._instance = new GigyaLibManager(mixin);
    }

    return this._instance;
  }

  get initialized() {
    return this._initialized;
  }

  async init(configData?: IConfig) {
    if (!this.initialized) {
      this.config.data = configData ?? {} as IConfig;
      await this._loadScript();
      this.events.subscribe();
      this._patchGigya();

      this._initialized = true;
    }
  }

  private async _loadScript() {
    const gigyaScriptUrl = this._getGigyaUrl();
    await loadScript(gigyaScriptUrl);
  }

  private _patchGigya() {
    Object.assign(window.gigya.thisScript.globalConf, gigyaFunctions);
  }

  private _getSsoGigyaUrl() {
    const baseUrl = getSsoGigyaBaseUrl();
    return new URL(`${baseUrl}/js/gigya.js`);
  }

  private _getGigyaUrl() {
    const gigyaScriptUrl = this.config.data.ssoEnabled ? this._getSsoGigyaUrl() : new URL("https://cdns.gigya.com/js/gigya.js");

    gigyaScriptUrl.searchParams.set("apiKey", this.config.data.apiKey);
    gigyaScriptUrl.searchParams.set("lang", `${this.config.data.lang}-${this.config.data.market}`);

    return gigyaScriptUrl;
  }
}

export const gigyaLibManager = GigyaLibManager.getInstance({ config, events });

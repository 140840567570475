import type { IShowScreenOptions } from "@fep/interfaces/iShowScreenOptions";
import { AuthService, getPageLocation } from "@fep/services";
import { showScreenSet } from "@fep/gigyaFunction";
import { getShowScreenOptions } from "@fep/util";

type IShowMyProfileScreenOptions = Omit<IShowScreenOptions, "redirect"> | string;

export async function showMyProfileScreen(options: IShowMyProfileScreenOptions) {
  const { pathname, search } = getPageLocation();
  const showScreenOptions = getShowScreenOptions<IShowMyProfileScreenOptions>(options, { redirect: pathname + search });
  const { containerID, __events } = showScreenOptions;

  const authService = new AuthService(showScreenOptions);
  await authService.handleAuthentication(true);

  await showScreenSet({ screenSet: "MyProfile", containerID, screenSetKey: "myProfile", __events });
}
